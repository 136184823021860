<template>
  <div>
    <div class="fs-20 fw-500" style="margin-bottom: 1rem">
      <i>Logging in&#8230;</i>
    </div>

    <loader loaderWidth="100px" class="login-loader" />
  </div>
</template>

<script>
  export default {
    name: 'Login',
    methods: {
      clearNotifications() {
        this.$snotify.clear();
      }
    },
    mounted() {
      this.clearNotifications();

      this.$store.dispatch('auth/redirectToAuthRequest');
    }
  };
</script>
