<template>
  <div></div>
</template>
<script>
  export default {
    mounted() {
      const { token, email } = this.$route.query;

      this.$store.commit('auth/auth_success', token);
      this.$store.commit('auth/setUsername', email);

      this.$store.dispatch('auth/getProfile').then(() => {
        this.$router.push({ name: 'Welcome' });
      });
    }
  };
</script>
